import React, { useCallback, useRef } from 'react';

import IconButton from '@material-ui/core/IconButton';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

export default function ToggleVideoButton(props: {
  disabled?: boolean;
  className?: string;
  iconWidth?: number;
  iconHeight?: number;
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <IconButton
      className={props.className}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || props.disabled}
      data-cy-audio-toggle
    >
      {isVideoEnabled ? (
        <VideoOnIcon width={props.iconWidth} height={props.iconHeight} />
      ) : (
        <VideoOffIcon width={props.iconWidth} height={props.iconHeight} />
      )}
    </IconButton>
  );
}
