import React from 'react';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import GreenRadio from '../GreenRadio';
import Typography from '../Typography';
import { useAppState } from '../../../../state';
import useDevices from '../../../../hooks/useDevices/useDevices';

const useLabelStyles = makeStyles(() =>
  createStyles({
    root: {
      justifyContent: 'space-between',
    },
    label: {
      color: '#fff',
    },
  })
);

export default function AudioOutputList() {
  const labelClasses = useLabelStyles();
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const { t } = useTranslation();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;

  return (
    <div className="inputSelect">
      {audioOutputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            {t('device_setting_popover.output_device')}
          </Typography>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            onChange={e => setActiveSinkId(e.target.value as string)}
            value={activeSinkId}
          >
            {audioOutputDevices.map(device => (
              <FormControlLabel
                value={device.deviceId}
                control={<GreenRadio />}
                label={device.label}
                labelPlacement="start"
                classes={labelClasses}
              />
            ))}
          </RadioGroup>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2">Audio Output</Typography>
          <Typography>{activeOutputLabel || 'System Default Audio Output'}</Typography>
        </>
      )}
    </div>
  );
}
