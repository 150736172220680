import React from 'react';

export default function MicOffIcon({ width = 24, height = 24 }: { width?: number; height?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M17.25 12.75a5.25 5.25 0 01-8.797 3.855l1.065-1.058a3.75 3.75 0 006.232-2.797V9.315l6.75-6.75L21.442 1.5 1.5 21.442 2.558 22.5l4.83-4.83a6.683 6.683 0 003.862 1.785V21h-3v1.5h7.5V21h-3v-1.545a6.75 6.75 0 006-6.705V10.5h-1.5v2.25zm-10.5.24V10.5h-1.5v2.25a6.75 6.75 0 00.188 1.568L6.75 12.99zm7.235-10.841a3.75 3.75 0 011.585 2.036l-7.32 7.32V5.25a3.75 3.75 0 015.735-3.101z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
