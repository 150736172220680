import React, { useState, useEffect } from 'react';
import { styled, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

const BackIconContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  left: '20px',
  top: '20px',
  cursor: 'pointer',
  zIndex: 5,
  width: 'fit-content',
  height: 'fit-content',
  [theme.breakpoints.down('sm')]: {
    position: 'static',
  },
}));

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams();
  const [step, setStep] = useState(Steps.deviceSelectionStep);

  const [name] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const onBackClick = () => {
    window.close();
  };

  return (
    <div>
      <BackIconContainer>
        <IconButton onClick={onBackClick}>
          <ArrowBackIcon style={{ color: '#fff' }} />
        </IconButton>
      </BackIconContainer>
      <IntroContainer>
        <MediaErrorSnackbar error={mediaError} />
        {step === Steps.deviceSelectionStep && (
          <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
        )}
      </IntroContainer>
    </div>
  );
}
