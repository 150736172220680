import React, { useEffect, useState } from 'react';
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import { interval } from 'd3-timer';
import Rating from '@material-ui/lab/Rating';
import { SvgIcon, withStyles, makeStyles, createStyles } from '@material-ui/core';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';

const StyledRating = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#06AF97',
  },
})(Rating);

const useIconStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 8,
      height: 32,
    },
  })
);

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext: AudioContext;

function IconContainer(props: { [x: string]: any; value: any }) {
  const { value, ...other } = props;
  const iconClasses = useIconStyles();
  return (
    <span {...other}>
      <SvgIcon viewBox="0 0 8 32" classes={iconClasses}>
        <svg width="8" height="32" viewBox="0 0 8 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
          <rect width="6" height="32" rx="3" />
        </svg>
      </SvgIcon>
    </span>
  );
}

export function initializeAnalyser(stream: MediaStream) {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;

  audioSource.connect(analyser);
  return analyser;
}

function AudioLevelMeter({ audioTrack, color = 'white' }: { audioTrack?: AudioTrack; color?: string }) {
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);
  const [level, setLevel] = useState<number>(0);

  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      // Here we create a new MediaStream from a clone of the mediaStreamTrack.
      // A clone is created to allow multiple instances of this component for a single
      // AudioTrack on iOS Safari.
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);

      // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
      // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
      // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
      // from a new audio device while the active audio device still has active tracks.
      const stopAllMediaStreamTracks = () => newMediaStream.getTracks().forEach(track => track.stop());
      audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  useEffect(() => {
    if (isTrackEnabled && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const timer = interval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;
        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }

        const volume = Math.min(32, Math.max(0, Math.log10(values / length / 2) * 16));

        setLevel(volume);
      }, 100);

      return () => {
        setLevel(0);
        timer.stop();
      };
    }
  }, [isTrackEnabled, analyser]);

  return <StyledRating readOnly max={32} value={level} IconContainerComponent={IconContainer} />;
}

export default React.memo(AudioLevelMeter);
