import React, { useMemo } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@material-ui/core';

import PinIcon from './PinIcon/PinIcon';
import LoadingIcon from '../../icons/LoadingIcon';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import ScreenShareIcon from '../../icons/ScreenShareIcon';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import UserAvatar from '../UserAvatar';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      flex: '0 0 auto',
      alignItems: 'center',
      overflow: 'hidden',
      '& video': {
        filter: 'none',
        objectFit: 'contain !important',
      },
      borderRadius: '4px',
      border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
      height: theme.headerHeight,
      width: `${(theme.headerHeight * 16) / 9}px`,
      background: 'black',
      marginRight: '8px',
      [theme.breakpoints.down('sm')]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginBottom: '0',
        fontSize: '10px',
        paddingTop: `${theme.sidebarMobileHeight - 2}px`,
      },
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: 0,
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#404040',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    screenShareIconContainer: {
      background: 'rgba(0, 0, 0, 0.5)',
      padding: '0.18em 0.3em',
      marginRight: '0.3em',
      display: 'flex',
      '& path': {
        fill: 'white',
      },
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '6px 8px',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '3px',
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 10,
      left: 10,
      fontSize: '12px',
    },
    typeography: {
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    hideParticipant: {
      display: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    participantName: {
      color: 'white',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '120px',
      fontSize: '12px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    audioIndicator: {
      width: 16,
      height: 16,
    },
    networkQuality: {
      width: '2em',
      height: '2em',
    },
    infoIcon: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 10,
      right: 10,
      fontSize: '12px',
      background: '#000000',
      borderRadius: '4px',
      padding: '2px',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  handleClickOpen?: () => void;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  handleClickOpen,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);
  const participantName = useMemo(() => participant.name, [participant.name]);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);
  const { waitingOthers } = useVideoContext();
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.hideParticipant]: hideParticipant,
        [classes.cursorPointer]: Boolean(onClick),
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
    >
      <div className={classes.infoContainer}>
        <div className={classes.networkQuality}>
          <NetworkQualityLevel participant={participant} />
        </div>
        <div className={classes.infoRowBottom}>
          {isScreenShareEnabled && (
            <span className={classes.screenShareIconContainer}>
              <ScreenShareIcon />
            </span>
          )}
          <span className={classes.identity}>
            {waitingOthers ? (
              <Typography variant="body1" className={classes.participantName} component="span">
                {t('waiting_for_others.shortened')}
              </Typography>
            ) : (
              <>
                <Typography variant="body1" className={classes.participantName} component="span">
                  {participantName}
                </Typography>
                <SvgIcon className={classes.audioIndicator}>
                  <AudioLevelIndicator audioTrack={audioTrack} />
                </SvgIcon>
              </>
            )}
          </span>
        </div>
        {!(isLocalParticipant && waitingOthers) && (
          <div className={classes.infoIcon} onClick={handleClickOpen}>
            <span>
              <InfoIcon style={{ color: '#fff', height: '100%' }} />
            </span>
          </div>
        )}
        <div>{isSelected && <PinIcon />}</div>
      </div>
      <div className={classes.innerContainer}>
        {isLocalParticipant && waitingOthers ? (
          <div className={classes.avatarContainer}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            {(!isVideoEnabled || isVideoSwitchedOff) && (
              <div className={classes.avatarContainer}>
                <UserAvatar name={participantName} size={64} fontSize={24} borderSize={2} />
              </div>
            )}
            {isParticipantReconnecting && (
              <div className={classes.reconnectingContainer}>
                <Typography variant="body1" className={classes.typeography}>
                  {t('reconnecting')}
                </Typography>
              </div>
            )}
            {children}
          </>
        )}
      </div>
    </div>
  );
}
