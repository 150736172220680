import React from 'react';
import { LocalAudioTrack } from 'twilio-video';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import AudioLevelMeter from '../AudioLevelIndicator/AudioLevelMeter';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles({
  container: {
    margin: '20px 0',
  },
  label: {
    marginBottom: '10px',
  },
});

export default function AudioTest() {
  const { localTracks } = useVideoContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;

  return (
    <div className={classes.container}>
      <Typography variant="subtitle2" gutterBottom className={classes.label}>
        {t('device_setting.audio.test_microphone')}
      </Typography>
      <Typography>
        <AudioLevelMeter audioTrack={localAudioTrack} color="black" />
      </Typography>
    </div>
  );
}
