import React from 'react';

export default function VideoOnIcon({ width = 24, height = 24 }: { width?: number; height?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        fill="#fff"
        d="M15.75 19.5H3A1.5 1.5 0 011.5 18V6A1.5 1.5 0 013 4.5h12.75a1.5 1.5 0 011.5 1.5v3.045l4.065-2.902a.75.75 0 011.185.607v10.5a.75.75 0 01-1.185.608l-4.065-2.903V18a1.5 1.5 0 01-1.5 1.5z"
      ></path>
    </svg>
  );
}
