import React from 'react';
import { LocalAudioTrack } from 'twilio-video';
import { FormControl, RadioGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import GreenRadio from '../GreenRadio';
import Typography from '../Typography';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../../constants';
import useDevices from '../../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

const useLabelStyles = makeStyles(() =>
  createStyles({
    root: {
      justifyContent: 'space-between',
    },
    label: {
      color: '#fff',
    },
  })
);

export default function AudioInputList() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const labelClasses = useLabelStyles();
  const { t } = useTranslation();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        {t('device_setting_popover.input_device')}
      </Typography>
      <div className="inputSelect">
        {audioInputDevices.length > 1 ? (
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={localAudioInputDeviceId || ''}
              onChange={e => replaceTrack(e.target.value as string)}
            >
              {audioInputDevices.map(device => (
                <FormControlLabel
                  value={device.deviceId}
                  control={<GreenRadio />}
                  label={device.label}
                  labelPlacement="start"
                  classes={labelClasses}
                />
              ))}
            </RadioGroup>
          </FormControl>
        ) : (
          <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
        )}
      </div>
    </div>
  );
}
