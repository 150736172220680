import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function CelebrationIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 31L21 23.5L7.5 10L0 31Z" fill="#EAB53E" />
        <path
          d="M18.7951 16.7952L27.1801 8.4102C27.9151 7.6752 29.1001 7.6752 29.8351 8.4102L30.7201 9.29521L32.3101 7.7052L31.4251 6.82021C29.8201 5.21521 27.1951 5.21521 25.5901 6.82021L17.2051 15.2052L18.7951 16.7952Z"
          fill="#EAB53E"
        />
        <path
          d="M12.0901 8.31994L11.2051 9.20494L12.7951 10.7949L13.6801 9.90994C15.2851 8.30494 15.2851 5.67994 13.6801 4.07494L12.7951 3.18994L11.2051 4.79494L12.0901 5.67994C12.8101 6.39994 12.8101 7.59994 12.0901 8.31994Z"
          fill="#EAB53E"
        />
        <path
          d="M22.5901 15.8202L20.2051 18.2052L21.7951 19.7952L24.1801 17.4102C24.9151 16.6752 26.1001 16.6752 26.8351 17.4102L29.2501 19.8252L30.8401 18.2352L28.4251 15.8202C26.8051 14.2152 24.1951 14.2152 22.5901 15.8202Z"
          fill="#EAB53E"
        />
        <path
          d="M19.5901 6.81994L14.2051 12.2049L15.7951 13.7949L21.1801 8.40994C22.7851 6.80494 22.7851 4.17994 21.1801 2.57494L18.7951 0.189941L17.2051 1.77994L19.5901 4.16494C20.3101 4.89994 20.3101 6.09994 19.5901 6.81994Z"
          fill="#EAB53E"
        />
      </svg>
    </SvgIcon>
  );
}
