import React, { useState, useMemo, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { Participant as IParticipant, RemoteParticipant as IRemoteParticipant } from 'twilio-video';
import { useMediaQuery } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Participant from '../Participant/Participant';
import ParticipantInfoDialog from './ParticipantInfoDialog';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useParticipants from '../VideoProvider/useParticipants/useParticipants';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowX: 'auto',
      background: 'rgb(79, 83, 85)',
      gridArea: '1 / 1 / 1 / 3',
      zIndex: 5,
      display: 'flex',
      justifyContent: 'flex-start',
      height: '100%',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        gridArea: '1 / 1 / 2 / 3',
      },
    },
    transparentBackground: {
      background: 'transparent',
    },
    scrollContainer: {
      width: 'calc(100% - 160px)',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0 16px',
      },
    },
    innerScrollContainer: {
      display: 'flex',
      overflowX: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    scrollButton: {
      width: '80px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function ParticipantList() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const localParticipant = useMemo(() => room!.localParticipant, [room]);
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
  const [open, setOpen] = useState<boolean>(false);
  const [participantInfo, setParticipantInfo] = useState<IParticipant | IRemoteParticipant>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const scrollRef = useRef<HTMLHeadingElement>(null!);
  const [showScrollBtn, setShowScrollBtn] = useState(false);

  const handleClickOpen = (participant: IParticipant | IRemoteParticipant) => {
    setOpen(true);
    setParticipantInfo(participant);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickParticipant = (participant: IRemoteParticipant) => {
    setSelectedParticipant(participant);
    isMobile && handleClickOpen(participant);
  };

  const onScrollClick = (action: string) => {
    const scrollLeft = action === 'back' ? scrollRef.current.scrollLeft - 512 : scrollRef.current.scrollLeft + 512;
    scrollRef.current.scroll({
      left: scrollLeft,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (isMobile) return;
    setShowScrollBtn(scrollRef.current?.clientWidth < scrollRef.current?.scrollWidth);
  }, [isMobile, participants.length]);

  if (isMobile && !participants.length) return null;

  return (
    <aside
      className={clsx(classes.container, {
        [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
      })}
    >
      <div className={classes.scrollButton}>
        {showScrollBtn && (
          <IconButton onClick={() => onScrollClick('back')}>
            <ArrowBackIosOutlinedIcon style={{ color: '#fff' }} />
          </IconButton>
        )}
      </div>
      <div className={classes.scrollContainer}>
        <div className={classes.innerScrollContainer} ref={scrollRef}>
          <Participant
            participant={localParticipant}
            isLocalParticipant={true}
            handleClickOpen={() => handleClickOpen(localParticipant)}
          />
          {participants.map(participant => {
            const isSelected = participant === selectedParticipant;
            const hideParticipant =
              isMobile && participant === mainParticipant && participant !== screenShareParticipant;
            return (
              <Participant
                key={participant.sid}
                participant={participant}
                isSelected={isSelected}
                onClick={() => handleClickParticipant(participant)}
                hideParticipant={hideParticipant}
                handleClickOpen={() => handleClickOpen(participant)}
              />
            );
          })}
        </div>
        <ParticipantInfoDialog open={open} handleClose={handleClose} participant={participantInfo} />
      </div>
      <div className={classes.scrollButton}>
        {showScrollBtn && (
          <IconButton onClick={() => onScrollClick('forward')}>
            <ArrowForwardIosOutlinedIcon style={{ color: '#fff' }} />
          </IconButton>
        )}
      </div>
    </aside>
  );
}
