import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Participant, RemoteParticipant } from 'twilio-video';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../UserAvatar';

interface ParticipantInfoDialogProps {
  open: boolean;
  handleClose?: () => void;
  participant?: Participant | RemoteParticipant;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '48px 20px',
      color: '#fff',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
      borderRadius: '8px',
      minWidth: '320px',
      maxWidth: '480px',
    },
    avatar: {
      display: 'flex',
      justifyContent: 'center',
    },
    innerContainer: {
      marginTop: '30px',
    },
    label: {
      fontWweight: 'bold',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.2px',
      color: '#989898',
    },
    content: {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.4px',
    },
    close: {
      position: 'absolute',
      top: 24,
      right: 24,
    },
  })
);

const StyledDialog = withStyles({
  paper: {
    background: 'rgb(79, 83, 85)',
  },
})((props: DialogProps) => <Dialog {...props} />);

export default function ParticipantInfoDialog({ open, handleClose, participant }: ParticipantInfoDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <StyledDialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <div className={classes.container}>
        <div className={classes.avatar}>
          <UserAvatar name={participant?.name} size={96} fontSize={34} />
        </div>
        <div className={classes.close}>
          <IconButton ria-label="close" component="span" onClick={handleClose}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
        <div className={classes.innerContainer}>
          <div className={classes.label}>{t('participant_info.name')}</div>
          <div className={classes.content}>{participant?.name}</div>
        </div>
        {participant?.jobPosition && (
          <div className={classes.innerContainer}>
            <div className={classes.label}>{t('participant_info.job_position')}</div>
            <div className={classes.content}>{participant?.jobPosition}</div>
          </div>
        )}
        {participant?.company && (
          <div className={classes.innerContainer}>
            <div className={classes.label}>{t('participant_info.company')}</div>
            <div className={classes.content}>{participant?.company}</div>
          </div>
        )}
      </div>
    </StyledDialog>
  );
}
