import React from 'react';

export default function MicIcon({ width = 24, height = 24 }: { width?: number; height?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14.652 15.402A3.75 3.75 0 018.25 12.75v-7.5a3.75 3.75 0 017.5 0v7.5a3.75 3.75 0 01-1.098 2.652zm2.598-2.652V10.5h1.5v2.25a6.75 6.75 0 01-6 6.705V21h3v1.5h-7.5V21h3v-1.545a6.75 6.75 0 01-6-6.705V10.5h1.5v2.25a5.25 5.25 0 0010.5 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
