import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import VideoTrack from '../../VideoTrack/VideoTrack';
import useDevices from '../../../hooks/useDevices/useDevices';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../../constants';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import UserAvatar from '../../UserAvatar';
import DeviceSelection from '../DeviceSelection';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    preview: {
      width: '214px',
      height: '160px',
      backgroundColor: '#484848',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '1em 0',
      },
    },
    deviceList: {
      width: 190,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        minHeight: 240,
      },
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  })
);

export default function VideoInputList() {
  const classes = useStyles();
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;
  const { t } = useTranslation();
  const [isVideoEnabled] = useLocalVideoToggle();

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  return (
    <Grid container justify="space-between" className={classes.container}>
      <div className={classes.preview}>
        {localVideoTrack ? (
          <VideoTrack track={localVideoTrack} isLocal />
        ) : (
          <div className={classes.avatarContainer}>
            <UserAvatar />
          </div>
        )}
      </div>
      <DeviceSelection
        devices={videoInputDevices}
        value={localVideoInputDeviceId || ''}
        onSelect={e => replaceTrack(e.target.value as string)}
        label={t('device_setting.video.select_a_camera')}
        className={classes.deviceList}
        disabled={!isVideoEnabled}
      />
    </Grid>
  );
}
