import React from 'react';

export default function VideoOffIcon({ width = 24, height = 24 }: { width?: number; height?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21.712 6.012a.75.75 0 01.383.07.75.75 0 01.405.668v10.5a.75.75 0 01-1.185.608l-4.065-2.903V18a1.5 1.5 0 01-1.5 1.5H5.58l-3 3-1.08-1.08L21.42 1.5l1.08 1.08-5.25 5.25v1.215l4.095-2.902a.75.75 0 01.367-.131zM3 4.5h12.232L1.5 18.218a2.138 2.138 0 010-.218V6A1.5 1.5 0 013 4.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
