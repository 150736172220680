import React from 'react';
import { SvgIcon, IconButton, Fade } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Popover';
import DeviceSetting from './DeviceSetting';

const usePopverStyles = makeStyles(() =>
  createStyles({
    paper: {
      bottom: 70,
      height: 'fit-content',
      top: 'unset !important',
      padding: '1em',
      borderRadius: 4,
      background: '#484848',
    },
  })
);

export default function DeviceSettingButton(props: { className?: string; iconWidth?: number; iconHeight?: number }) {
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const popoverClasses = usePopverStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <div>
      <IconButton className={props.className} onClick={handleClick}>
        <SvgIcon style={{ width: props.iconWidth || 24, height: props.iconHeight || 24 }}>
          <svg width="22" height="26" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.04199 16.1485H21.9803V10.1631L27.0206 15.4835V0.852543L21.9803 6.17288V0.1875H9.40032C10.4194 1.10979 11.0597 2.44298 11.0597 3.92571V7.39302C11.26 7.31995 11.4764 7.28009 11.702 7.28009C12.7376 7.28009 13.577 8.11956 13.577 9.15509C13.577 12.2719 11.7147 14.9538 9.04199 16.1485Z"
              fill="white"
            />
            <rect x="3.58301" y="1.48975" width="4.55729" height="10.4167" rx="2.27865" fill="white" />
            <path
              d="M11.0596 10.1001C11.0596 12.5563 8.80302 14.5474 6.01933 14.5474M6.01933 14.5474C3.23563 14.5474 0.979004 12.5563 0.979004 10.1001M6.01933 14.5474V18.5006"
              stroke="white"
              strokeWidth="1.25"
              strokeLinecap="round"
            />
          </svg>
        </SvgIcon>
      </IconButton>
      <Dialog id="device-setting-popver" open={isDialogOpen} onClose={handleClose} classes={popoverClasses}>
        <DeviceSetting />
      </Dialog>
    </div>
  );
}
