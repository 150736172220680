import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { Typography, Grid, createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';

import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import DeviceSettingButton from '../Buttons/DeviceSettingButton/DeviceSettingButton';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle/useLocalVideoToggle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#000',
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 10,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    baseButton: {
      margin: theme.spacing(1.5),
      borderRadius: '100%',
      width: 64,
      height: 64,
      [theme.breakpoints.down('sm')]: {
        width: 50,
        height: 50,
      },
    },
    greenButton: {
      background: '#06AF97 !important',
      '&:hover': {
        background: '#06AF97 !important',
      },
    },
    redButton: {
      background: `${theme.brand} !important`,
      color: '#fff',
      '&:hover': {
        background: `${theme.brand} !important`,
      },
    },
    greyButton: {
      background: '#484848 !important',
      color: '#fff',
      '&:hover': {
        background: '#484848 !important',
      },
    },
  })
);

const IconSize = {
  desktop: {
    width: 45,
    height: 45,
  },
  mobile: {
    width: 32,
    height: 32,
  },
};

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const [isAudioEnabled] = useLocalAudioToggle();
  const [isVideoEnabled] = useLocalVideoToggle();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const size = isMobile ? IconSize.mobile : IconSize.desktop;

  return (
    <>
      {isSharingScreen && (
        <Grid container justify="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <DeviceSettingButton
          className={clsx(classes.baseButton, classes.greyButton)}
          iconWidth={size.width}
          iconHeight={size.height}
        />
        <ToggleAudioButton
          disabled={isReconnecting}
          className={clsx(classes.baseButton, {
            [classes.greenButton]: isAudioEnabled,
            [classes.greyButton]: !isAudioEnabled,
          })}
          iconWidth={size.width}
          iconHeight={size.height}
        />
        <ToggleVideoButton
          disabled={isReconnecting}
          className={clsx(classes.baseButton, {
            [classes.greenButton]: isVideoEnabled,
            [classes.greyButton]: !isVideoEnabled,
          })}
          iconWidth={size.width}
          iconHeight={size.height}
        />
        <EndCallButton
          className={clsx(classes.redButton, classes.baseButton)}
          iconWidth={size.width}
          iconHeight={size.height}
        />
      </footer>
    </>
  );
}
