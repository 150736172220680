import React from 'react';
import { LocalAudioTrack } from 'twilio-video';
import { useTranslation } from 'react-i18next';
import DeviceSelection from '../DeviceSelection';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function AudioInputList() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const { t } = useTranslation();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div>
      <div className="inputSelect">
        <DeviceSelection
          devices={audioInputDevices}
          value={localAudioInputDeviceId || ''}
          onSelect={e => replaceTrack(e.target.value as string)}
          label={t('device_setting.audio.audio_input')}
          className=""
          fullWidth
        />
      </div>
    </div>
  );
}
