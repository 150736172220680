import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

const StyledTypography = withStyles({
  root: {
    color: '#fff',
  },
})((props: TypographyProps) => <Typography {...props} />);

export default StyledTypography;
