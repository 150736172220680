import React from 'react';
import { useTranslation } from 'react-i18next';
import DeviceSelection from '../DeviceSelection';
import { useAppState } from '../../../state';
import useDevices from '../../../hooks/useDevices/useDevices';

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const { t } = useTranslation();

  return (
    <div className="inputSelect">
      <DeviceSelection
        devices={audioOutputDevices}
        value={activeSinkId || ''}
        onSelect={e => setActiveSinkId(e.target.value as string)}
        label={t('device_setting.audio.audio_output')}
        className=""
        fullWidth
      />
    </div>
  );
}
