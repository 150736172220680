import React from 'react';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const StyledMenuItem = withStyles({
  selected: {
    background: '#F4F8FF !important',
    color: '#CF2F2F',
  },
})(MenuItem);

const useStyles = makeStyles({
  label: {
    marginBottom: '10px',
  },
});

interface DeviceSelectionProps {
  devices: MediaDeviceInfo[];
  value: string;
  onSelect: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  label: string;
  className: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

const defaultOptions = [
  {
    label: '----------------------',
    value: '',
    key: 'empty',
  },
  {
    label: 'default',
    value: 'default',
    key: 'default',
  },
];

export default function DeviceSelection({
  devices,
  value,
  onSelect,
  label,
  className,
  fullWidth = false,
  disabled = false,
}: DeviceSelectionProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      <Typography
        variant="subtitle2"
        gutterBottom
        color={devices.length <= 1 ? 'textSecondary' : 'initial'}
        className={classes.label}
      >
        {label}
      </Typography>
      <Select
        onChange={e => onSelect}
        value={value || ''}
        variant="outlined"
        displayEmpty
        disabled={disabled || !devices.length}
      >
        {devices.map(device => (
          <StyledMenuItem value={device.deviceId} key={device.deviceId}>
            {device.label}
          </StyledMenuItem>
        ))}
        {!(devices.length && value) &&
          defaultOptions.map(device => (
            <StyledMenuItem value={device.value} key={device.key}>
              {device.label}
            </StyledMenuItem>
          ))}
      </Select>
      {!devices.length && <FormHelperText error>{t('device_setting.no_device_detected')}</FormHelperText>}
    </FormControl>
  );
}
