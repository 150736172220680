import React, { useEffect, useState, useContext, createContext } from 'react';
import { RemoteParticipant, Room } from 'twilio-video';

type participantContextType = [RemoteParticipant[]];

export const participantContext = createContext<participantContextType>(null!);

export default function useParticipants() {
  const [participants] = useContext(participantContext);
  return participants;
}

type participantsProviderProps = {
  room: Room | null;
  setWaitingOthers: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
};

export function ParticipantsProvider({ room, setWaitingOthers, children }: participantsProviderProps) {
  const [participants, setParticipants] = useState<RemoteParticipant[]>(null!);

  const fetchParticipants = async () => {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/meetings/${room!.name}/participants`;
    return fetch(endpoint!, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(({ participants: xParticipants }) => xParticipants || [])
      .catch(error => console.log(error));
  };

  const updateParticipants = async () => {
    const xParticipants = await fetchParticipants();
    setWaitingOthers(room?.participants.size === 0);

    if (xParticipants?.length) {
      setParticipants(prevParticipants => {
        const updatedParticipants = [...prevParticipants];
        if (xParticipants?.length && updatedParticipants.length) {
          updatedParticipants.forEach(participant => {
            const xParticipant = xParticipants.find(({ id }: { id: string }) => id === participant.identity);
            if (xParticipant) {
              participant.name = xParticipant.name;
              participant.jobPosition = xParticipant.jobPosition;
              participant.company = xParticipant.company;
            }
          });
        }
        return updatedParticipants;
      });

      const localParticipant =
        xParticipants.find(({ id }: { id: string }) => id === room!.localParticipant.identity) || {};
      room!.localParticipant.name = localParticipant.name;
      room!.localParticipant.jobPosition = localParticipant.jobPosition;
      room!.localParticipant.company = localParticipant.company;
    }
  };

  useEffect(() => {
    !!room?.localParticipant && updateParticipants();
  }, [room?.localParticipant]);

  useEffect(() => {
    if (room) {
      setParticipants(Array.from(room?.participants.values() ?? []));
      const participantConnected = (participant: RemoteParticipant) => {
        setParticipants(prevParticipants => [...prevParticipants, participant]);
        updateParticipants();
        setWaitingOthers(false);
      };

      const participantDisconnected = (participant: RemoteParticipant) =>
        setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);

  return <participantContext.Provider value={[participants]}>{children}</participantContext.Provider>;
}
