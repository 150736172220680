import React, { useState } from 'react';
import { FormControl, RadioGroup, FormControlLabel, Grid } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import GreenRadio from '../GreenRadio';
import Typography from '../Typography';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../../../constants';
import useDevices from '../../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

const useLabelStyles = makeStyles(() =>
  createStyles({
    root: {
      justifyContent: 'space-between',
    },
    label: {
      color: '#fff',
    },
  })
);

export default function VideoInputList() {
  const labelClasses = useLabelStyles();
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const { t } = useTranslation();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  return (
    <Grid container justify="space-between">
      <FormControl fullWidth>
        <Typography variant="subtitle2" gutterBottom>
          {t('device_setting_popover.camera_setting')}
        </Typography>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          onChange={e => videoInputDevices.length > 1 && replaceTrack(e.target.value as string)}
          value={localVideoInputDeviceId || ''}
        >
          {videoInputDevices.map(device => (
            <FormControlLabel
              value={device.deviceId}
              control={<GreenRadio />}
              label={device.label}
              labelPlacement="start"
              classes={labelClasses}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}
