import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Grid, Button, Theme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import AudioInputList from '../../../components/DeviceSelectionDialog/AudioInputList/AudioInputList';
import AudioOutputList from '../../../components/DeviceSelectionDialog/AudioOutputList/AudioOutputList';
import VideoInputList from '../../../components/DeviceSelectionDialog/VideoInputList/VideoInputList';
import AudioTest from '../../../components/DeviceSelectionDialog/AudioTest';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

const useStyles = makeStyles((theme: Theme) => ({
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    margin: theme.spacing(1),
    borderRadius: '100%',
    width: 40,
    height: 40,
    '& > span': {
      width: 'auto',
    },
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  container: {
    width: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '1em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  joinButton: {
    height: 40,
    backgroundColor: '#CF2F2F',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  toggleButtons: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: '12px',
      display: 'flex',
      top: '12px',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  enabledButton: {
    backgroundColor: '#06AF97 !important',
    '&:hover': {
      backgroundColor: '#06AF97 !important',
    },
  },
  disabledButton: {
    backgroundColor: '#C4C4C4 !important',
    '&:hover': {
      backgroundColor: '#C4C4C4 !important',
    },
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

interface URLParameterType {
  meetingId?: string;
  participantId?: string;
}

export default function DeviceSelectionScreen({ setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { t } = useTranslation();
  const [isAudioEnabled] = useLocalAudioToggle();
  const [isVideoEnabled] = useLocalVideoToggle();

  const handleJoin = () => {
    const { meetingId, participantId }: URLParameterType = queryString.parse(document.location.search);
    if (meetingId && participantId) {
      getToken(meetingId, participantId).then(token => {
        if (token) {
          videoConnect(token);
          // process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
        }
      });
    }
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justify="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {t('device_setting.join_meeting')}
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        {t('device_setting.audio.audio_settings')}
      </Typography>
      <div className={classes.listSection}>
        <AudioInputList />
      </div>
      <div className={classes.listSection}>
        <AudioOutputList />
      </div>
      <div className={classes.listSection}>
        <AudioTest />
      </div>
      <Typography variant="h5" className={classes.title}>
        {t('device_setting.video.video_settings')}
      </Typography>
      <div className={classes.listSection}>
        <VideoInputList />
      </div>
      <Grid container justify="space-between" alignItems="center">
        <Grid className={classes.toggleButtons}>
          <ToggleAudioButton
            className={clsx(classes.deviceButton, {
              [classes.enabledButton]: isAudioEnabled,
              [classes.disabledButton]: !isAudioEnabled,
            })}
            disabled={disableButtons}
          />
          <ToggleVideoButton
            className={clsx(classes.deviceButton, {
              [classes.enabledButton]: isVideoEnabled,
              [classes.disabledButton]: !isVideoEnabled,
            })}
            disabled={disableButtons}
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          data-cy-join-now
          onClick={handleJoin}
          disabled={disableButtons}
          className={classes.joinButton}
        >
          {t('device_setting.ready_to_join')}
        </Button>
      </Grid>
    </>
  );
}
