import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
  iconWidth?: number;
  iconHeight?: number;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <IconButton
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      data-cy-audio-toggle
    >
      {isAudioEnabled ? (
        <MicIcon width={props.iconWidth} height={props.iconHeight} />
      ) : (
        <MicOffIcon width={props.iconWidth} height={props.iconHeight} />
      )}
    </IconButton>
  );
}
