import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import CelebrationIcon from './CelebrationIcon';
import UserAvatar from '../UserAvatar';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useTrack from '../../hooks/useTrack/useTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  identity: {
    alignItems: 'center',
    display: 'flex',
  },
  infoContainer: {
    position: 'absolute',
    zIndex: 2,
    left: '80px',
    bottom: '38px',
    background: 'rgba(0, 0, 0, 0.4)',
    color: '#fff',
    padding: '12px 28px 12px 20px',
    maxWidth: 522,
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      left: '16px',
      bottom: '24px',
    },
  },
  reconnectingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(40, 42, 43, 0.75)',
    zIndex: 1,
  },
  fullWidth: {
    gridArea: '2 / 1 / 2 / 3',
    [theme.breakpoints.down('sm')]: {
      gridArea: '2 / 1 / 3 / 3',
    },
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#404040',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    '& svg': {
      transform: 'scale(2)',
    },
  },
  infoContent: {
    display: 'flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '29px',
  },
  topic: {
    zIndex: 5,
    color: '#fff',
    padding: '10px 12px',
    borderRadius: '38px',
    background: 'rgba(0, 0, 0, 0.4)',
    position: 'absolute',
    lineHeight: '20px',
    maxWidth: '522px',
    left: '80px',
    top: '12px',
    [theme.breakpoints.down('sm')]: {
      left: '16px',
    },
  },
  name: {
    maxWidth: 365,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.5em',
  },
  longText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  celebrationContainer: {
    zIndex: 5,
    background: 'rgba(26, 26, 26, 0.9)',
    padding: '16px',
    borderRadius: '8px',
    display: 'flex',
    margin: 'auto',
    position: 'absolute',
    left: 0,
    right: 0,
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      margin: '0 20px',
    },
  },
  celebrationText: {
    fontSize: '20px',
    color: '#fff',
    letterSpacing: '1px',
    lineHeight: '20px',
    marginLeft: '5px',
  },
}));

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
  const classes = useStyles();
  const { room, topic, waitingOthers } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const isLocal = localParticipant === participant;
  const { t } = useTranslation();

  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(p => p.trackName.includes('camera'));
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isAudioEnabled = useIsTrackEnabled(audioTrack);

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);
  const [celebrationOpen, setCelebrationOpen] = useState<boolean>(true);

  const dismissCelebration = () => {
    setCelebrationOpen(false);
  };

  useEffect(() => {
    setTimeout(function() {
      dismissCelebration();
    }, 5000);
  }, []);

  return (
    <div
      data-cy-main-participant
      data-cy-participant={participant.identity}
      className={clsx(classes.container, {
        [classes.fullWidth]: !isRemoteParticipantScreenSharing,
      })}
    >
      <div className={classes.infoContainer}>
        <div className={classes.infoContent}>
          <div className={classes.identity}>
            <div className={classes.name}>{participant.name}</div>
            {isLocal && ' (You)'}
            {screenSharePublication && ' - Screen'}
          </div>
          <div className={classes.icons}>
            <NetworkQualityLevel participant={participant} />
            <AudioLevelIndicator audioTrack={audioTrack} />
          </div>
        </div>
        {participant.jobPosition && <div className={classes.longText}>{participant.jobPosition}</div>}
        {participant.company && <div className={classes.longText}>{participant.company}</div>}
      </div>
      {waitingOthers && celebrationOpen && (
        <div className={classes.celebrationContainer} onClick={dismissCelebration}>
          <CelebrationIcon />
          <div className={classes.celebrationText}>{t('waiting_for_others.expanded')}</div>
        </div>
      )}
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <div className={classes.avatarContainer}>
          <UserAvatar
            name={participant.name}
            size={144}
            fontSize={56}
            color={isAudioEnabled ? '#1AFFD9' : undefined}
            borderSize={6}
          />
        </div>
      )}
      {isParticipantReconnecting && (
        <div className={classes.reconnectingContainer}>
          <Typography variant="body1" style={{ color: 'white' }}>
            {t('reconnecting')}
          </Typography>
        </div>
      )}
      {children}
      <div className={classes.topic}>{topic}</div>
    </div>
  );
}
