import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function EndCallButton(props: { className?: string; iconWidth?: number; iconHeight?: number }) {
  const { room } = useVideoContext();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExit = () => {
    room!.disconnect();
    window.close();
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} className={props.className} data-cy-disconnect>
        <ExitToAppIcon style={{ width: props.iconWidth, height: props.iconHeight }} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('end_call_dialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('end_call_dialog.content')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="default" variant="outlined">
            {t('end_call_dialog.stay_here')}
          </Button>
          <Button onClick={handleExit} variant="contained" color="secondary">
            {t('end_call_dialog.exit_now')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
