import React from 'react';
import { Grid } from '@material-ui/core';
import Divider, { DividerProps } from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import VideoInputList from './VideoInputList/VideoInputList';

const StyledDivider = withStyles({
  root: {
    backgroundColor: '#595959',
    margin: '6px 0',
  },
})((props: DividerProps) => <Divider {...props} />);

export default function DeviceSetting() {
  return (
    <Grid>
      <VideoInputList />
      <StyledDivider />
      <AudioInputList />
      <StyledDivider />
      <AudioOutputList />
      <StyledDivider />
    </Grid>
  );
}
